import React from "react"
import { Link } from "gatsby"

import SEO from "../components/seo/seo"
import { StaticImage } from 'gatsby-plugin-image'

import peopleIcon from './../images/icons/bx-group.svg';
import processIcon from './../images/icons/bx-shape-circle.svg';

const AboutUsPage = () => (
  <>
    <SEO title="About us" />
    <section className="section hero">
      <div className="container big">
        <div className="col img-wrapper">
          <StaticImage
            src="./../images/about-us.png"
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            placeholder="none"
            alt="About us"
          />
        </div>
        <div className="col text">
          <h3 className="title" title="About us">About Us</h3>
          <p className="desc">
            You will think you have heard this before. College buddies taking corporate jobs. After several successes, they decide to start something of their own. But instead of jumping all in on their pet dream, they test the waters by doing it as a side hustle. Then side hustle becomes so big that it can no longer be managed on the sides. Buddies quit their cushy corporate titles to make a place under the sun. Yup, that’s us. <br/><br/>
            With years of professional experience and skillset honed through hustling, we are uniquely positioned to offer our clients the best of both worlds. Speed and agility of a startup, and predictability & reliability of a conventional business. 91.7% of our business occurs through recurring clients. Which is a testament to our value proposition &amp; consistency.
          </p>
          <Link to="#secret" className="btn-link">Know more</Link>
        </div>
      </div>
    </section>
    <section className="section secret" id="secret">
      <div className="container big">
        <h3 className="title" title="Our secret">Our secret</h3>
        <p className="desc small">
          People tend to look for a team to deliver the app. Isn’t it simply better to look for a team that will make sure it’s a success?<br/><br/>
          Our secret sauce to ensure success? ...<br/>
        </p>
        <div className="card-header">
          <img src={peopleIcon} width="36px" alt="People icon"/>
          People
        </div>
        <p className="desc small">
          Along with aptitude, experience and comprehension, we look for people who love to do what they do. Who give extreme attention to detail and are devoted to their work. Not only this, our teammates are given complete autonomy so that everyone can unleash their creative potential, while feeling responsible. More importantly, we encourage our people to constantly upgrade their knowledge.
        </p>
        <div className="card-header">
          <img src={processIcon} width="36px" alt="Process icon"/>
          Process
        </div>
        <p className="desc small">
          It is our magic key to ensure success every time. Our internal standards &amp; practices have been honed through years of experimentation. So that milestone achievement is assured, always. And things do not stop at the delivery side. Strict testing protocols ensure smooth run in production. And lastly, periodic communication with our clients for feedback &amp; review ensures that the project exceeds client expectations every single time.
        </p>
      </div>
    </section>
    <section className="section gain">
      <div className="container big">
        <h3 className="title">What you gain by hiring us?</h3>
        <ul className="list">
          <li>A professional product advisory and a multi stack team for software delivery</li>
          <li>Purpose-driven approach, every step of the way.</li>
          <li>Bolder choices and transformative change through user-validated digital products.</li>
          <li>Process flow that is “natural” for the user, resulting in intuitive applications. And, what is understood easily is also adopted easily. Thus ensuring success of your projects.</li>
        </ul>
      </div>
    </section>
    <section className="section hire-us">
      <div className="container">
        <span className="text">
          Ready to build your next innovation? 
        </span>
        <a className="btn primary" href="https://cal.com/nikhil01/30min" target="_blank">Hire Us</a>
      </div>
    </section>
  </>
)

export default AboutUsPage
